import { Button, forceRefreshPage } from "@sizdevteam1/funjoiner-uikit";

export const ErrorBoundaryPage = () => {
  return (
    <div className="mx-auto mt-[160px] flex w-[366px] flex-col items-center text-center">
      <i className="icon thinking-sun-icon " />
      <div className="typography-h3 mt-6 flex flex-col gap-1 text-text-color">
        <span>Hmm, something’s not quite right...</span>
        <span>
          The screen has gone blank<br></br>— but we got you covered!
        </span>
      </div>
      <div className="typography-main_sb mt-4 flex flex-col gap-2 text-gray-text-color">
        <span>
          This could be due to cache, cookies, or <br></br>security features
          protecting your data.
        </span>
        <span>
          Try refreshing the page, closing and <br></br>reopening your browser.
        </span>
      </div>
      <Button onClick={forceRefreshPage} className="mt-6">
        Refresh Now
      </Button>
    </div>
  );
};
