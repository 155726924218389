import { Navigate, RouteProps, useLocation } from "react-router-dom";
import PublicRouteContent from "src/components/PublicRouteContent";
import useStores from "../hooks/useStores";
import React, { Suspense } from "react";
import { pathFromLocation, ROUTES } from "../stores/RouterStore";
import { Loader, Splash } from "@sizdevteam1/funjoiner-uikit";
import { observer, Observer } from "mobx-react-lite";

interface IProps {
  title?: string;
  children: React.ReactNode;
}

const PrivateRouteContent: React.FC<IProps> = observer(
  ({ title, children }) => {
    const { authStore, routerStore } = useStores();
    const customerEmail = routerStore.currentSearch.get("customer_email");
    const location = useLocation();

    return (
      <PublicRouteContent title={title}>
        {!authStore.initialized ? (
          <Splash pathToAnimation={"/splash.json"} />
        ) : authStore.loggedIn ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: ROUTES.SIGN_IN,

              search:
                location.search +
                (customerEmail ? "&customer_email=" + customerEmail : ""),
            }}
            state={{
              fromState: location.state,
              from: pathFromLocation(location),
            }}
            replace={true}
          />
        )}
      </PublicRouteContent>
    );
  }
);
export default PrivateRouteContent;
