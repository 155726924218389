import RootStore from "../stores/RootStore";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  loadStripe,
  PaymentRequest,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { STRIPE_PK } from "../config";
import api, { ICardDTO, IOrderDTO } from "src/services/api";
import cardFromStripePaymentMethod from "../util/cardFromStripePaymentMethod";
import {
  ICalculatedOrderDTO,
  ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO,
  ICalculateOrderDTO,
  ICalculateScheduleAndPayOrderDTO,
  IScheduleAndPayOrderDTO,
} from "../services/api/orders";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { BaseOrderCalculatorVm } from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/BaseOrderCalculatorVm";
import assertNever from "@sizdevteam1/funjoiner-uikit/util/assertNever";
import delay from "@sizdevteam1/funjoiner-uikit/util/delay";
import isAbortError from "@sizdevteam1/funjoiner-uikit/util/isAbortError";

type IncompleteOrder =
  | {
      type: "schedule_and_pay";
      order: ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO;
      payload: ICalculateScheduleAndPayOrderDTO;
    }
  | {
      type: "order";
      order: ICalculatedOrderDTO;
      payload: ICalculateOrderDTO;
    };

type IncompleteOrderPayload =
  | {
      type: "schedule_and_pay";
      payload: ICalculateScheduleAndPayOrderDTO;
    }
  | {
      type: "order";
      payload: ICalculateOrderDTO;
    };

export default class PaymentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  private stripePromiseResolver: (v: Stripe | null) => void = () => {};

  stripePromise = new Promise<Stripe | null>(
    (resolve) => (this.stripePromiseResolver = resolve)
  );

  @observable
  _incompleteOrderPayload: IncompleteOrderPayload | null = null;

  _orderCalculator = new OrderCalculatorVm(
    computed(() => this._incompleteOrderPayload),
    (order) => {
      if (order.type === "schedule_and_pay") {
        return api.orders.calculateScheduleAndPayOrder(order.payload);
      } else if (order.type === "order") {
        return api.orders.calculate(order.payload);
      } else {
        assertNever(order);
      }
    }
  );

  @computed
  get incompleteOrder() {
    return this._orderCalculator?.order;
  }

  @computed
  get incompleteOrderPayload() {
    return this._incompleteOrderPayload;
  }

  @computed
  get availablePaymentPlans() {
    return this._orderCalculator.availablePaymentPlans;
  }

  @computed
  get isModifyingOrder() {
    return this._orderCalculator.isLoading;
  }

  @action
  setIncompleteOrder = (order: IncompleteOrder | null) => {
    this._orderCalculator.setValue({ type: "result", result: order?.order });
    this._incompleteOrderPayload = order;
  };

  @observable
  completedOrder: {
    order: IOrderDTO | IScheduleAndPayOrderDTO;
    card: ICardDTO;
  } | null = null;

  @action
  saveCompletedOrder(order: IOrderDTO, card: ICardDTO) {
    this.completedOrder = { order, card };
  }

  @observable
  initialized = false;

  createPaymentMethod = async (
    elementsCard: StripeCardElement | StripeCardNumberElement
  ) => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elementsCard,
    });

    if (!result.paymentMethod) {
      console.error("[error]", result.error);
      throw new Error(result.error.message);
    }

    return cardFromStripePaymentMethod(result.paymentMethod);
  };

  confirmPayment = async (
    clientSecret: string,
    paymentMethodId: string,
    savePaymentMethod: boolean
  ) => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
      setup_future_usage: savePaymentMethod ? "off_session" : undefined,
    });
    return error;
  };

  createPaymentRequest = async (
    description: string,
    amount: number,
    pending: boolean
  ): Promise<PaymentRequest> => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    return stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: description,
        amount: amount,
        pending: pending,
      },
      disableWallets: ["googlePay", "browserCard", "link"],
    });
  };

  @action.bound
  async confirmSetupIntent(clientSecret: string, paymentMethodId: string) {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const { error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodId,
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  waitForOrderInstallmentToProceed(
    orderId: number,
    installment_id: string,
    timeout: number
  ) {
    const to = delay(timeout);
    to.then(() => (expired = true));

    let expired = false;
    const promise = (): Promise<IScheduleAndPayOrderDTO> =>
      api.orders.getOrderById(orderId).then((o) => {
        if (
          o.payment_plan?.installments.find((i) => i.id === installment_id)
            ?.payment?.status === "completed"
        ) {
          to.cancel();
          return o as IScheduleAndPayOrderDTO;
        }
        if (expired)
          return Promise.reject(
            new Error(
              "The transaction was successful, " +
                "but the server still hasn't processed it." +
                " Please try to refresh the page in a minute."
            )
          );
        return delay(1000).then((_) => promise());
      });
    return promise();
  }

  @action.bound async attachPaymentPlan(paymentPlanId: string) {
    if (
      this._incompleteOrderPayload == null ||
      this._incompleteOrderPayload.type !== "schedule_and_pay"
    ) {
      return;
    }
    this._incompleteOrderPayload.payload.payment_plan_id = paymentPlanId;
    await this._orderCalculator.recalculate();
  }

  @action.bound async detachPaymentPlan() {
    if (
      this._incompleteOrderPayload == null ||
      this._incompleteOrderPayload.type !== "schedule_and_pay"
    ) {
      return;
    }
    this._incompleteOrderPayload.payload.payment_plan_id = undefined;
    await this._orderCalculator.recalculate();
  }

  @action.bound
  async applyPromocode(promocode: string) {
    if (this._incompleteOrderPayload == null) {
      return;
    }

    this._incompleteOrderPayload.payload.promocode_id = promocode;
    await this._orderCalculator.recalculate();
  }

  @action.bound
  async removePromocode() {
    if (this._incompleteOrderPayload == null) {
      return;
    }

    this._incompleteOrderPayload.payload.promocode_id = undefined;
    await this._orderCalculator.recalculate();
  }

  @action
  init = async () => {
    const stripeAccount = await api.integrations.stripeAccount();
    this.stripePromiseResolver(
      stripeAccount == null
        ? null
        : stripeAccount.type === "connect"
        ? await loadStripe(STRIPE_PK, {
            stripeAccount: stripeAccount.account_stripe_id,
          })
        : await loadStripe(stripeAccount.public_key)
    );

    runInAction(() => {
      this.initialized = true;
    });
  };
}

class OrderCalculatorVm extends BaseOrderCalculatorVm<
  IncompleteOrderPayload,
  ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO | ICalculatedOrderDTO
> {
  @computed
  get order(): ICalculatedOrderDTO | undefined {
    return this.isScheduleAndPay(this.calculationResult)
      ? this.calculationResult.order
      : this.calculationResult;
  }

  @computed
  get availablePaymentPlans() {
    return this.isScheduleAndPay(this.calculationResult)
      ? this.calculationResult.available_payment_plans
      : [];
  }

  isScheduleAndPay = (
    order:
      | ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO
      | ICalculatedOrderDTO
      | undefined
  ): order is ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO => {
    if (order == null) return false;
    return "available_payment_plans" in order;
  };

  recalculate = async (): Promise<void> => {
    try {
      return await super.recalculate();
    } catch (e) {
      if (!isAbortError(e)) {
        notificator.error("Error!", e);
      }
    }
  };
}
